/*reset styles*/
.login { background: url(img/x-bg.svg) center center no-repeat #fff; background-size: cover; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center;
    align-items: center; min-height: 24em; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; 
    h1 { display: block; background: url(img/bigpixel.svg) center center no-repeat; width: 225px; height: 63px; margin: 0 auto 60px; 
    	a { display: none; }
    }
}

/* Colours */
$black: #283636;
$white: #ffffff;
$green: #05DDA8;
$blue: #00B9EB;
$body-font-color: #666666;
$body-font-family: 'Montserrat', sans-serif;

#login { position: relative; width: 100%; max-width: 447px; padding:20px; border:0; box-shadow: none;
	a, input { transition: all 0.3s ease-in-out;  }
	form { margin:0; padding:0; background: none; box-shadow: none; }
	label { color: $black; font: 800 normal 14px $body-font-family; }
	input.input { font: 300 normal 16px $body-font-family; color: $body-font-color; padding: 17px 20px; margin:7px 0 25px; background: $white; border: 1px solid #C8C8C8; border-radius: 3px; box-shadow: none; 
		&:focus { border: 1px solid $black; -webkit-box-shadow: 0px 3px 11px 0px rgba(200,200,200,1); -moz-box-shadow: 0px 3px 11px 0px rgba(200,200,200,1); box-shadow: 0px 3px 11px 0px rgba(200,200,200,1); }
	}  
	input.button { border: 0; background: $green; color: $white; font: 800 normal 16px $body-font-family; text-transform: uppercase; padding: 20px 20px 17px 20px; height: auto; box-shadow: none; text-shadow: none; line-height: 1;
		&:hover { background: darken($green, 3%); }
	} 
	p.forgetmenot {  padding-top: 11px; display: block;
		label { font-weight: 300; color: #666;
			input { box-shadow: none; background: $white; }
			input:focus { border-color: $black; box-shadow: 0 0 2px rgba(40,54,54,.5); }
			input[type=checkbox]:checked:before { content: "\f147"; margin: -3px 0 0 -4px; color: $black; }
		}
	}
	#backtoblog { display: none; }
	#nav { text-align: center; font: 300 normal 14px $body-font-family; margin-top: 60px;
		a { color: $blue; text-decoration: underline; 
			&:hover { text-decoration: none; color: $black; } 
		} 	
	}
	.message { padding:0; box-shadow: none; background: none; border: 0; font: 300 normal 16px $body-font-family; color: $body-font-color; line-height: 1.5em; }
}

.login #login_error { font: 300 normal 16px $body-font-family; padding: 17px 20px; }
